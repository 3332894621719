import { SelectDisclosure } from '@/scripts/forms/select-disclosure'
import { SelectDisclosureWithoutForm } from '@/scripts/forms/select-disclosure'
import { safeDefineElement } from '@/scripts/functions'
import { initShopify, uCoastWindow } from '@/scripts/setup'

import { initializeThemeEditor } from '@/scripts/theme-editor'
import { ShowMore } from '@/scripts/content/show-more'

declare let window: uCoastWindow

function initialize() {
	// Shopify window stuff
	if (typeof window.Shopify == 'undefined') {
		window.Shopify = initShopify()
	}
	safeDefineElement(SelectDisclosure)
	safeDefineElement(SelectDisclosureWithoutForm)
	safeDefineElement(ShowMore)
	// this needs to be last
	if (window.Shopify.designMode) {
		initializeThemeEditor()
	}
}

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', initialize)
} else {
	initialize()
}

export const shopify = window.Shopify
